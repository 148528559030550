const fa = {
  // BANKS LIST
  egtesad_novin: "بانک اقتصادنوین",
  ansar: "بانک انصار",
  iran_zamin: "بانک ایران زمین",
  parsian: "بانک پارسیان",
  pasargad: "بانک پاسارگاد",
  ayande: "بانک آینده",
  tejarat: "بانک تجارت",
  tosee_tavon: "بانک توسعه تعاون",
  tose_saderat_iran: "بانک توسعه صادرات",
  hekmat_iranian: "بانک حکمت ایرانیان",
  dey: "بانک دی",
  refah_kargaran: "بانک رفاه کارگران",
  saman: "بانک سامان",
  sepah: "بانک سپه",
  sarmaye: "بانک سرمایه",
  sina: "بانک سینا",
  shahr: "بانک شهر",
  saderat_iran: "بانک صادرات",
  sanat_va_madan: "بانک صنعت و معدن",
  mehr_iran: "بانک مهر",
  gavvamin: "بانک قوامین",
  kar_afarin: "بانک کارآفرین",
  keshavarzi: "بانک کشاورزی",
  gardeshgari: "بانک گردشگری",
  markazi: "بانک مرکزی",
  maskan: "بانک مسکن",
  mellat: "بانک ملت",
  melli: "بانک ملی",
  mehre_egtesad: "بانک مهر اقتصاد",
  post_bank: "پست بانک ایران",
  melal: "مؤسسه اعتباری ملل",
  resalat: "بانک رسالت",
  khavar_miyane: "بانک خاورمیانه",
  nour: "بانک نور",
  //
  ariomex: "آریومکس",
  ariomexSupport: "پشتیبانی آریومکس",
  search: "جستجو",
  searchPlaceholder: "جستجو ...",
  login: "ورود",
  getAccessToYourAccount: "به حساب خود دسترسی پیدا کنید",
  username: "نام کاربری",
  password: "رمز عبور",
  tfaCode: "رمز دومرحله‌ای",
  totalMembers: "مجموع کاربران",
  KYClevelOne: "سطح یک",
  KYClevelOneMembers: "کاربر سطح یک",
  KYClevelTwoMembers: "کاربر سطح دو",
  KYClevelTwoMembersSelfie: "سطح دو سلفی",
  KYClevelTwoMembersBiometric: "سطح دو بیومتریک",
  totalUnVerifiedMembers: "احراز‌ نشده",
  cardsVerify: "کارت‌های در انتظار بررسی",
  shebaVerify: "شبا‌های در انتظار بررسی",
  todayIRTDeposits: "واریز‌های ریالی امروز",
  yesterdayIRTDeposits: "واریز‌های ریالی دیروز",
  todayCryptoDeposits: "واریز‌های کریپتو امروز",
  yesterdayCryptoDeposits: "واریز‌های کریپتو دیروز",
  todayIRTWithdraws: "برداشت‌های ریالی امروز",
  yesterdayIRTWithdraws: "برداشت‌های ریالی دیروز",
  todayCryptoWithdraws: "برداشت‌های کریپتو امروز",
  yesterdayCryptoWithdraws: "برداشت‌های کریپتو دیروز",
  completed: "تکمیل شده",
  pending: "در حال بررسی",
  rejected: "رد شده",
  canceled: "لغو شده",
  accountVerify: "مدارک در انتظار بررسی",
  members: "کاربران",
  searchMembers: "جستجوی کاربران",
  deposits: "واریز‌ها",
  withdraws: "برداشت‌ها",
  bankAccounts: "حساب‌های بانکی",
  securityVerification: "یررسی امنیتی",
  pleaseCompleteSecurityVerification: "لطفا بررسی امنیتی را کامل کنید.",
  pleaseEnterUserName: "لطفا نام کاربری را وارد کنید.",
  pleaseEnterPassword: "لطفا رمز را وارد کنید.",
  pleaseEnterGoogleAuthenticationCode:
    "لطفا کد تایید Google Authenticator را وارد کنید.",
  profile: "پروفایل",
  dashboard: "داشبورد",
  users: "کاربران",
  activity: "فعالیت‌ها",
  settings: "تنظیمات",
  help: "راهنما",
  language: "زبان",
  addAccount: "افزودن حساب دیگر",
  signOut: "خروج",
  policy: "قوانین و مقررات",
  terms: "شرایط",
  cookies: "کوکی ها",
  statusPlaceHolder: "به‌روزرسانی وضعیت",
  name: "نام",
  email: "ایمیل",
  referralNumber: "تعداد کاربران معرفی شده",
  referredBy: "معرفی کننده",
  accountType: "نوع حساب",
  birthday: "تاریخ تولد",
  VIPLevel: "سطح وی‌آی‌پی",
  signUpDate: "تاریخ ثبت‌نام",
  KYCDate: "تاریخ تایید و یا رد شدن مدارک",
  lastLogin: "زمان آخرین ورود",
  cellphone: "شماره همراه",
  landlineNumber: "شماره ثابت",
  IDCode: "کد ملی",
  telegram: "آي‌دی تلگرام",
  telegramChat: "آی‌دی چت تلگرام",
  depositCount: "تعداد واریز‌ها",
  withdrawCount: "تعداد برداشت‌ها",
  tradeCount: "تعداد معاملات",
  IRTWithdrawLimit: "محدودیت برداشت روزانه تومان",
  cryptoWithdrawLimit: "محدودیت برداشت روزانه رمز‌ارز",
  monthlyWithdrawLimit: "محدودیت برداشت ماهانه",
  KYCStatus: "وضعیت تایید مدارک",
  cellphoneStatus: "وضعیت تایید تلفن همراه",
  landlineStatus: "وضعیت تایید تلفن ثابت",
  emailStatus: "وضعیت تایید ایمیل",
  KYCPermission: "اجازه احراز هویت",
  WAPPStatus: "وضعیت احراز هویت واتساپی",
  autoWithdraw: "برداشت اتوماتیک",
  googleAuthStatus: "وضعیت 2FA",
  banStatus: "وضعیت مسدودیت",
  depositPermission: "اجازه واریز",
  withdrawPermission: "اجازه برداشت",
  tradePermission: "اجازه معامله",
  VerificationPhoto: "تصویر احراز‌ هویت",
  addInfo: "افزودن توضیحات",
  upload: "آپلود",
  changeHistory: "تاریخچه تغییرات",
  assetsChart: "نمودار دارایی‌ها",
  exportPositionsHistory: "اکسپورت تاریخچه سفارشات",
  accept: "تایید",
  cancel: "لغو",
  reject: "رد",
  confirmChanges: "ثبت تغییرات",
  cancelChanges: "لغو تغییرات",
  KYCPhoto: "تصویر احراز‌ هویت",
  fullname: "نام و نام‌خانوادگی",
  gender: "جنسیت",
  shabandari: "نارک شابندری",
  nationalId: "کد ملی",
  ban: "مسدودیت",
  verification: "احراز هویت",
  verified: "تایید شده",
  uuid: "شناسه کاربر",
  noResultFound: "چیزی یافت نشد!",
  weCantFindAnyResultsMatchingYourSearch:
    "نتیجه‌ای که با جستجوی شما همخوانی داشته باشد وجود ندارد.",
  tryAgain: "تلاش مجدد",
  moreReasons:
    "در صورتیکه دلایل فوق کافی نبود لطفا دلایل را همانند مثال در سطرهای جداگانه بنویسید.",
  rejectDocumentMessage1: "وضوح تصویر کم است و مدارک خوانا نیست",
  rejectDocumentMessage2: "تصویر مطابق با راهنمای احراز‌ هویت نیست",
  rejectDocumentMessage3: "عدم وجود کارت‌ملی در تصویر",
  rejectDocumentMessage4: "عدم وجود کارت‌بانکی در تصویر",
  rejectDocumentMessage5: "عدم وجود تعهدنامه در تصویر",
  rejectDocumentMessage6: "کارت‌ملی خوانا نیست",
  rejectDocumentMessage7: "تعهدنامه باید دستنویس باشد",
  rejectDocumentMessage8: "تاریخ تعهدنامه به‌روز نیست",
  rejectDocumentMessage9: "تصویر توسط نرم‌افزار دستکاری شده است",
  rejectDocumentMessage10: "چهره به‌صورت کامل مشخص نیست",
  rejectDocumentMessage11: "نام و نام‌خانوادگی بر روی کارت‌بانکی ناخواناست",
  rejectDocumentMessage12: "رعایت حجاب اسلامی الزامی است",
  rejectDocumentMessage13: "تصویر آینه‌ای است",
  rejectDocumentMessage14: "نوع مدرک ارسالی مورد‌قبول نیست",
  rejectDocumentMessage15: "متن تعهدنامه اشتباه است",
  rejectDocumentMessage16: "کد‌ملی شناسنامه ناخواناست",
  rejectDocumentMessage17: "کد‌ملی پاسپورت ناخواناست",
  rejectDocumentMessage18: "تاریخ انقضای پاسپورت تمام شده است و معتبر نمی‌باشد",
  rejectDocumentMessage19:
    "شماره کارت‌بانکی به صورت کامل در تصویر مشخص نمی‌باشد",
  rejectDocumentMessage20: "تعهدنامه قلم‌خوردگی دارد",
  rejectDocumentMessage21: "تعهدنامه ارسالی فاقد امضا، تاریخ و اسم شخص می‌باشد",
  rejectDocumentExample:
    "- نمونه دلیل رد (خط تیره فاصله دلیل رد) اگر دلایل فوق کافیست تیک هر کدام را زده این قسمت را خالی بگذارید",
  changeStatus: "تغییر وضعیت",
  changeName: "تغییر نام",
  dailyIRTWithdrawLimitChange: "تغییر محدودیت برداشت روزانه تومان",
  dailyCryptoWithdrawLimitChange: "تغییر محدودیت برداشت روزانه رمزارز",
  monthlyWithdrawLimitChange: "تغییر محدودیت برداشت ماهانه",
  changeBirthday: "تغییر تاریخ تولد",
  male: "مذکر",
  female: "مونث",
  date: "تاریخ",
  supportMember: "پشتیبان",
  user: "کاربر",
  subject: "موضوع",
  description: "توضیحات",
  total: "مجموع",
  somethingWentWrong: "خطایی رخ داده است.",
  trades: "معاملات",
  openOrders: "سفارش‌های باز",
  ordersHistory: "تاریخچه سفارشات",
  dropYourFilesHere: "فایل‌های خود را اینجا بارگذاری کنید.",
  successfullyUploaded: "با موفقیت بارگذاری شد.",
  fileIsSuccessfullyAdded: "فایل با موفقیت اضافه شد.",
  fileLoading: "در حال بارگذاری ...",
  errorWhileUploading: "خطا در بارگذاری",
  invalidFileFormat: "فرمت فایل قابل قبول نیست",
  emailVerification: "تایید ایمیل",
  depositAddress: "آدرس واریز",
  memo: "ممو",
  all: "همه",
  isBanned: "مسدود",
  notBanned: "فعال",
  IRT: "ریالی",
  crypto: "رمزارز",
  dateTime: "زمان",
  orderID: "شماره سفارش",
  gateway: "درگاه",
  amount: "مقدار",
  bank: "بانک",
  card: "شماره کارت",
  moreInfo: "بیشتر",
  network: "شبکه",
  status: "وضعیت",
  coin: "ارز",
  no: "خیر",
  yes: "بله",
  isVerified: "تایید شده",
  notVerified: "تایید نشده",
  toItemOf: "نمایش {{start}} تا {{end}} از کل {{total}} مورد",
  notSet: "تعیین نشده",
  cancelled: "لغو شده",
  internal: "داخلی",
  hash: "هش",
  changeKYCStatus: "تغییر وضعیت تایید مدارک",
  changeLandlineStatus: "تغییر وضعیت تایید تلفن ثابت",
  changeEmailStatus: "تغییر وضعیت تایید ایمیل",
  changeKYCPermission: "تغییر اجازه احراز هویت",
  changeAutoWithdrawStatus: "تغییر وضعیت برداشت اتوماتیک",
  changeBanStatus: "تغییر وضعیت مسدودیت",
  changeDepositPermission: "تغییر اجازه واریز",
  changeWithdrawPermission: "تغییر اجازه برداشت",
  changeTradePermission: "تغییر اجازه معامله",
  anErrorOccurred: "خطایی رخ داده است!",
  depositInfo: "جزئیات واریز",
  back: "بازگشت",
  checkDocument: "لطفا تصویر کاربر را بررسی نمایید",
  currentName: "نام فعلی",
  currentBirthday: "تاریخ تولد فعلی",
  currentIRTWithdrawLimit: "محدودیت برداشت روزانه تومان فعلی",
  currentCryptoWithdrawLimit: "محدودیت برداشت روزانه کریپتو فعلی",
  currentMonthlyWithdrawLimit: "محدودیت برداشت ماهانه فعلی",
  currentKYCPermission: "اجازه احراز‌ هویت فعلی",
  currentAutomaticWithdrawPermission: "وضعیت برداشت اتوماتیک فعلی",
  currentDepositPermission: "اجازه واریز فعلی",
  currentWithdrawPermission: "اجازه برداشت فعلی",
  currentTradePermission: "اجازه معامله فعلی",
  currentLandlineStatus: "وضعیت تلفن ثابت فعلی",
  currentKYCStatus: "وضعیت تایید مدارک فعلی",
  currentBanStatus: "وضعیت مسدودیت فعلی",
  dateTimeRequest: "زمان درخواست",
  dateTimeExecute: "زمان اجرا",
  exportData: "دریافت خروجی",
  withdrawInfo: "جزئیات برداشت",
  withdrawAddress: "آدرس برداشت",
  iban: "شماره شبا",
  processing: "در حال ارسال",
  processingIRT: "در حال پردازش",
  new: "جدید",
  pendingWithdraw: "در انتظار",
  originAddress: "آدرس مبدا",
  destinationAddress: "آدرس مقصد",
  details: "جزئیات",
  deposit_uuid: "شناسه واریز",
  user_uuid: "شناسه کاربر",
  confirmations: "تعداد کانفیرم‌ها",
  withdraw_uuid: "شناسه برداشت",
  fee: "کارمزد",
  isAuto: "اتوماتیک",
  send_amount: "مقدار نهایی",
  will_execute_after_time: "تاخیر",
  problematic: "دارای خطا",
  serverMismatch: "مغایرت سرور",
  tableMismatch: "مغایرت جدول",
  serverUnreachable: "سرور قطع",
  darkMode: "حالت شب",
  searchDeposits: "جستجوی واریز‌ها",
  searchWithdraws: "جستجوی برداشت‌ها",
  ibans: "شماره‌های شبا‌",
  cards: "کارت‌های بانکی",
  getDetails: "دریافت جزئیات",
  approve: "تایید",
  notApprove: "رد",
  actions: "عملیات",
  check: "بررسی",
  checkAgainPlease: "لطفا اطلاعات را مجدد بررسی نمایید",
  checkPlease: "لطفا اطلاعات را بررسی نمایید",
  excelFileIsSuccessfullyCreated: "فایل اکسل با موفقیت ذخیره شد.",
  match: "تطابق",
  noMatch: "عدم تطابق",
  notChecked: "بررسی نشده",
  advancedSearch: "جستجوی پیشرفته",
  cardCount: "تعداد کارت‌های بانکی",
  ibanCount: "تعداد شماره‌های شبا",
  referral: "کد معرف",
  accountLevel: "سطح",
  changeGenderStatus: "جنسیت",
  currentGender: "جنسیت فعلی",
  executeNow: "اجرا آنی",
  invalidUploadTypeMsg: "فرمت فایل آپلود شده صحیح نیست.",
  invalidUploadSizeMsg: "سایز فایل باید کوچکتر از {{size}} باشد.",
  auto: "اتوماتیک",
  manual: "دستی",
  normal: "نرمال",
  economy: "اکونومی",
  KYC: "احراز هویت",
  KYClvl1: " احراز هویت سطح یک",
  KYClvl1Time: " تاریخ احراز هویت سطح یک",
  KYClvl2: " احراز هویت سطح دو",
  KYClvl2Time: " تاریخ احراز هویت سطح دو",
  noInternetConnection: "عدم دسترسی به اینترنت",
  operators: "اپراتور‌ها",
  management: "مدیریت",
  levelOne: "سطح 1",
  levelTwo: "سطح 2",
  levelThree: "سطح 3",
  levelFour: "سطح 4",
  levelFive: "سطح 5",
  fromDate: "تاریخ شروع",
  toDate: "تاریخ پایان",
  choose: "انتخاب کنید",
  totalOperators: "مجموع اپراتور‌ها",
  online: "آنلاین",
  offline: "آفلاین",
  balance: "دارایی‌ها",
  inOrder: "در سفارش",
  pendingInWithdraw: "در برداشت",
  addresses: "آدرس‌ها",
  show: "نمایش",
  update: "به‌روزرسانی",
  networks: "شبکه‌ها",
  operatorProfile: "پروفایل اپراتور",
  information: "اطلاعات",
  general: "عمومی",
  permissions: "دسترسی‌ها",
  edit: "ویرایش",
  searchCoin: "جستجوی ارز",
  editBalance: "ویرایش موجودی",
  nameEn: "نام انگلیسی",
  nameFa: "نام فارسی",
  chooseCoin: "لطفا ارز موردنظر را انتخاب کنید",
  enterAmount: "مقدار را وارد کنید",
  increase: "افزایش",
  decrease: "کاهش",
  confirmModifyBalanceIncrease: "شما در حال افزایش موجودی کاربر هستید",
  confirmModifyBalanceDecrease: "شما در حال کاهش موجودی کاربر هستید",
  rejectCardMessage1: "شماره کارت اشتباه است",
  rejectCardMessage2: "شماره کارت تکراری است",
  rejectCardMessage3: "کارت بانکی به اسم شخص دیگری است",
  rejectIbanMessage1: "شماره شبا اشتباه است",
  rejectIbanMessage2: "شماره شبا تکراری است",
  rejectIbanMessage3: "شماره شبا به اسم شخص دیگری است",
  bankData: "اطلاعات دریافت شده از بانک",
  fillField: "لطفا فرم را پر کنید",
  pleaseSelectCoin: "لطفا ارز موردنظر خود را انتخاب کنید",
  pleaseEnterAmount: "لطفا مقدار موردنظر را وارد کنید",
  currentAmount: "مقدار فعلی",
  newAmount: "مقدار پس از تغییرات",
  pleaseEnterName: "لطفا نام را وارد نمایید",
  pleaseEnterLastName: "لطفا نام خانوادگی را وارد نمایید",
  pleaseEnterGender: "لطفا جنسیت را انتخاب کنید",
  pleaseEnterBirthday: "لطفا تاریخ تولد را وارد نمایید",
  pleaseEnterReason: "لطفا دلایل رد شدن را انتخاب و یا وارد نمایید",
  pleaseChooseOption: "لطفا گزینه‌ی موردنظر را انتخاب نمایید",
  pleaseChooseStatus: "لطفا وضعیت موردنظر را انتخاب نمایید",
  pleaseEnterDescription: "لطفا توضیحات موردنظر را وارد نمایید",
  pleaseEnterTx: "لطفا هش تراکنش موردنظر را وارد نمایید",
  actionsHistory: "تاریخچه تغییرات",
  change: "تغییر",
  agent: "پلتفرم",
  ip: "آی پی",
  location: "مکان",
  time: "زمان تغییر",
  operator_id: "نام کاربری",
  operator_uuid: "آی دی اپراتور",
  operator_name: " نام اپراتور",
  viewAllServices: "نمایش همه سرویس‌ها",
  onlineOperators: "اپراتورهای فعال",
  offlineOperators: "اپراتورهای غیر فعال",
  operatorsActionsHistory: "تاریخچه تغییرات ایجاد شده توسط اپراتور‌ها",
  nameUser: "نام کاربر",
  cellphoneUser: "شماره همراه کاربر",
  nationalIdUser: "شماره ملی کاربر",
  operatorsInfo: "اطلاعات اپراتور‌ها",
  addOperator: "افزودن اپراتور‌",
  add: "افزودن",
  loginId: "نام کاربری",
  active: "فعال",
  deactive: "غیرفعال",
  reset: "بازیابی",
  seen: "دیده شد",
  pleaseEnterCellphone: "لطفا شماره همراه را وارد نمایید",
  PleaseEnterOperatorId: "لطفا نام کاربری را وارد نمایید",
  pleaseChoosePermission: "لطفا دسترسی‌ها را انتخاب نمایید",
  withdrawLine: "صف برداشت",
  successfullyCopied: "با موفقیت کپی شد",
  coinsManagement: "مدیریت رمزارز‌ها",
  image: "نماد",
  depositMin: "حداقل واریز",
  withdrawMin: "حداقل برداشت",
  depositFee: "کارمزد واریز",
  withdrawFee: "کارمزد برداشت",
  depositStatus: "وضعیت واریز",
  withdrawStatus: "وضعیت برداشت",
  icon: "نماد",
  IRTEquivalent: "معادل ریال",
  USDTEquivalent: "معادل تتر",
  totalUsersBalances: "مجموع دارایی کاربران",
  download: "دانلود",
  DocumentPhoto: "تصویر احراز هویت",
  selectLanguage: "انتخاب زبان",
  ticketPlatform: "سامانه تیکت",
  createTime: "زمان ایجاد",
  lastUpdateTime: "زمان آخرین بروزرسانی",
  ticketId: "شناسه تیکت",
  title: "عنوان",
  creator: "ایجاد کننده",
  assignee: "اختصاص داده شده",
  searchTicket: "جستوجوی تیکت‌ها",
  open: "باز",
  reOpen: "بازگشایی مجدد",
  close: "بستن",
  closed: "بسته",
  allTickets: "همه‌ی تیکت‌ها",
  openTickets: "تیکت‌های باز",
  send: "ارسال",
  createTicket: "ایجاد تیکت",
  operator: "اپراتور",
  text: "متن",
  pleaseEnterTitle: "لطفا عنوان را انتخاب نمایید",
  pleaseEnterAssigneeNumber: "لطفا شماره همراه وارد نمایید",
  pleaseEnterText: "لطفا متن را وارد نمایید",
  history: "تاریخچه",
  assign: "اختصاص دادن",
  currentAssignee: "اختصاص شده فعلی",
  seeDetails: "مشاهده جزئیات",
  systemHealth: "وضعیت سیستم‌ها",
  searchActionsHistory: "جستجوی تاریخچه‌ی اقدامات",
  action: "عملیات",
  referrals: "معرفی شدگان",
  irtDeposits: "واریزهای ریالی",
  cryptoDeposits: "واریزهای رمزارز",
  irtWithdraws: "برداشت‌های ریالی",
  cryptoWithdraws: "برداشت‌های رمزارز",
  tradeInfo: "اطلاعات معامله",
  bidOrderID: "شناسه سفارش خرید",
  askOrderID: "شناسه سفارش فروش",
  buyerUuid: "شناسه خریدار",
  buyerName: "نام خریدار",
  buyerEmail: "ایمیل خریدار",
  buyerNid: "کدملی خریدار",
  buyerPhone: "شماره همراه خریدار",
  sellerUuid: "شناسه فروشنده",
  sellerName: "نام فروشنده",
  sellerEmail: "ایمیل فروشنده",
  sellerNid: "کدملی فروشنده",
  sellerPhone: "شماره همراه فروشنده",
  symbol: "جفت‌ارز",
  volume: "حجم",
  price: "قیمت",
  pendingOrder: "باز",
  sl: "حدضرر",
  filledp: "تکمیل شده (%)",
  filled: "تکمیل شده",
  type: "نوع",
  side: "سمت",
  updateTime: "زمان آخرین آپدیت",
  tokenSymbol: "نماد توکن",
  tokenName: "نام توکن",
  fullnameEn: "نام کامل شبکه به انگلیسی",
  fullnameFa: "نام کامل شبکه به فارسی",
  isDefault: "پیشفرض",
  hasMemo: "دارای ممو",
  memoRegex: "ریجکس ممو",
  addressUrl: "آدرس URL",
  addressRegex: "ریجکس آدرس",
  transactionUrl: "URL تراکنش",
  tokenDecimal: "اعشار توکن",
  hasDepositFee: "دارای کارمزد واریز",
  hasDepositFeePercentage: "دارای درصد کارمزد واریز",
  depositFeePercentage: "درصد کارمزد واریز",
  minimumDeposit: "حداقل واریز",
  withdrawPrecision: "دقت برداشت",
  minimumWithdraw: "حداقل برداشت",
  contractAddress: "آدرس قرارداد",
  contractAddressUrl: "آدرس URL قرارداد",
  confirmationCount: "تعداد تایید",
  pleaseEnterVIPLevel: "لطفا سطح را انتخاب نمایید",
  currentLevel: "سطح فعلی",
  currentFamilyName: "نام خانوادگی فعلی",
  uploadedDocuments: "مدارک بارگذاری شده",
  uploadTime: "زمان آپلود",
  documentDetails: "جزئیات مدرک",
  firstName: "نام",
  lastName: "نام خانوادگی",
  telegramId: "آیدی تلگرام",
  telegramChatId: "آیدی چت تلگرام",
  changeVipLevel: "تغییر سطح VIP",
  more: "بیشتر",
  myTickets: "تیکت های من",
  newTickets: "تیکت های جدید",
  customerName: "نام کاربر",
  hideFromUser: "از کاربر پنهان شود",
  selectAll: "انتخاب همه",
  userCellphone: "شماره همراه کاربر",
  taggedDepositPermission: "اجازه واریز شناسه‌دار",
  taggedDepositPermissionText:
    "شما در حال فعال کردن واریز شناسه دار کاربر هستید، آیا تایید می نمایید؟",
  confirm: "تایید",
  inputDescription: "توضیحات را وارد کنید",
  level0: "سطح ۰",
  level1: "سطح ۱",
  level2: "سطح ۲",
  level3: "سطح ۳",
  clickToPreview: "جهت مشاهده بر روی عکس کلیک کنید",
  pleaseEnterNid: "لطفا شماره ملی را وارد نمایید",
  notice: "اطلاع",
  refNumber: "کد رهگیری",
  bypass_irip: "اجازه دسترسی خارج از ایران",
  bypass_iripChange: "تغییر اجازه دسترسی خارج از ایران",
  currentBypass_iripPermission: "اجازه دسترسی فعلی",
  withdrawDelay: " تاخیر در برداشت",
  currentWithdrawDelay: "وضعیت تاخیر برداشت فعلی",
  captchaResult: "نتیجه کپچا",
  pleaseChooseOperator: "لطفا اپراتور را انتخاب کنید",
  irt: "تومان",
  changes: "اقدامات",
  tickets: "تیکت‌ها",
  zibal: "زیبال",
  pay: "پی",
  vandar: "وندار",
  jibit: "جیبیت",
  sync: "هماهنگ‌ سازی",
  userChanges: "اقدامات کاربر",
  system: "سیستم",
  years: "سال",
  months: "ماه",
  days: "روز",
  age: "سن",
  searchUsers: "جستجوی کاربران",
  fileUpdate: "فایل آماده آپلود",
  pleaseEnterMessage: "لطفا پیام را وارد نمایید",
  fileTypeError: "فرمت فایل مورد تایید نیست",
  fileSizeError: "حجم فایل بیش از {{maxFileSize}} مگابایت می‌باشد",
  assetsDetails: "جزئیات دارایی ",
  sell: "فروش",
  buy: "خرید",
  market: "مارکت",
  limit: "لیمیت",
  tp: "حد سود",
  sendSMS: "ارسال پیامک",
  smsText: "متن پیامک",
  pleaseUseEnglishNumbers: "لطفا از اعداد انگلیسی استفاده کنید",
  buyerFee: "کارمزد خریدار",
  sellerFee: "کارمزد فروشنده",
  usersActions: "اقدامات کاربران",
  lastUserChange: "آخرین تغییر",
  action2: "اقدام",
  lastLoginDate: "آخرین ورود",
  tfa: "کد دو عاملی",
  tfaTurnOffText:
    "شما در حال غیرفعالسازی کد دو عاملی کاربر هستید، آیا تایید می نمایید؟",
  OperatorCreator: "اپراتور ایجاد کننده",
  coldWalletBalance: "دارایی‌های کیف پول سرد",
  wait72Hour: "۷۲ ساعت",
  payroll: "حسابداری",
  spendTime: "زمان تراکنش",
  spend: "هزینه",
  topup: "واریز",
  excel: "اکسل",
  pleaseSelectTimeRange: "لطفا بازه زمانی را انتخاب کنید",
  delete: "حذف",
  deletePayrollWarnText: "آیا از حذف مطمئن هستید؟",
  rial: "ریال",
  reasons: "دلایل",
} as const;

export type Fa = typeof fa;

export default fa;
