const en = {
  // BANKS LIST
  egtesad_novin: "Eghtesad Novin Bank",
  ansar: "Ansar Bank",
  iran_zamin: "Iranzamin Bank",
  parsian: "Parsian Bank",
  pasargad: "Pasargard Bank",
  ayande: "Ayandeh Bank",
  tejarat: "Tejarat Bank",
  tosee_tavon: "Tose'e Ta'avon Bank",
  tose_saderat_iran: "Tose'e Saderat Bank",
  hekmat_iranian: "Hekmat Iranian Bank",
  dey: "Dey Bank",
  refah_kargaran: "Refah Kargaran Bank",
  saman: "Saman Bank",
  sepah: "Sepah Bank",
  sarmaye: "Sarmayeh Bank",
  sina: "Sina Bank",
  shahr: "Shahr Bank",
  saderat_iran: "Saderat Bank",
  sanat_va_madan: "San'at va Madan Bank",
  mehr_iran: "Mehr Bank",
  gavvamin: "Qavamin Bank",
  kar_afarin: "Kar Afarin Bank",
  keshavarzi: "Keshavarzi Bank",
  gardeshgari: "Gardeshgari Bank",
  markazi: "Markazi Bank",
  maskan: "Maskan Bank",
  mellat: "Mellat Bank",
  melli: "Melli Bank",
  mehre_egtesad: "Mehr e Eghtesad Bank",
  post_bank: "Iran Post Bank",
  melal: "Melal Bank",
  resalat: "Resalat Bank",
  khavar_miyane: "Khavarmianeh Bank",
  nour: "Noor Bank",
  //
  ariomex: "Ariomex",
  ariomexSupport: "Ariomex Support",
  search: "Search",
  searchPlaceholder: "Search ...",
  login: "Login",
  getAccessToYourAccount: "Get access to your account",
  username: "User Name",
  password: "Password",
  tfaCode: "Two Factor Code",
  totalMembers: "Total members",
  KYClevelOne: "KYC level 1",
  KYClevelOneMembers: " KYC level one users",
  KYClevelTwoMembers: "KYC level two users",
  KYClevelTwoMembersSelfie: "KYC level 2 Selfie",
  KYClevelTwoMembersBiometric: "KYC level 2 Biometric",
  totalUnVerifiedMembers: "Unverified",
  cardsVerify: "Cards to be verified",
  shebaVerify: "Ibans to be verified",
  todayIRTDeposits: "Today's IRT Deposits",
  yesterdayIRTDeposits: "Yesterday's IRT Deposits",
  todayCryptoDeposits: "Today's Crypto Deposits",
  yesterdayCryptoDeposits: "Yesterday's Crypto Deposits",
  todayIRTWithdraws: "Today's IRT Withdraws",
  yesterdayIRTWithdraws: "Yesterday's IRT Withdraws",
  todayCryptoWithdraws: "Today's Crypto Withdraws",
  yesterdayCryptoWithdraws: "Yesterday's Crypto Withdraws",
  completed: "Completed",
  pending: "Pending",
  rejected: "Rejected",
  canceled: "Canceled",
  accountVerify: "Accounts to be verified",
  members: "Members",
  searchMembers: "Search Members",
  deposits: "Deposits",
  withdraws: "Withdraws",
  bankAccounts: "Bank Accounts",
  securityVerification: "Security Verification",
  pleaseCompleteSecurityVerification: "Please complete security verification.",
  pleaseEnterUserName: "Please enter username",
  pleaseEnterPassword: "Please enter password",
  pleaseEnterGoogleAuthenticationCode:
    "Please enter Google Authentication code.",
  profile: "Profile",
  dashboard: "Dashboard",
  users: "Users",
  activity: "Activity",
  settings: "Settings",
  help: "Help",
  language: "Language",
  addAccount: "Add another account",
  signOut: "Sign Out",
  policy: "Privacy Policy",
  terms: "Terms",
  cookies: "Cookies",
  statusPlaceHolder: "Update Your Status",
  name: "Name",
  email: "Email",
  referralNumber: "Number of users referred",
  referredBy: "Referred By",
  accountType: "Account type",
  birthday: "Birthday",
  VIPLevel: "VIP level",
  signUpDate: "SignUp Date",
  KYCDate: "KYC date",
  lastLogin: "Last login date",
  cellphone: "Cellphone",
  landlineNumber: "Landline number",
  IDCode: "ID number",
  telegram: "Telegram ID",
  telegramChat: "Telegram Chat ID",
  depositCount: "Number of deposits",
  withdrawCount: "Number of withdraws",
  tradeCount: "Number of trades",
  IRTWithdrawLimit: "Daily IRT withdraw limit",
  cryptoWithdrawLimit: "Daily cryptocurrency withdraw limit",
  monthlyWithdrawLimit: "Monthly withdraw limit",
  KYCStatus: "KYC verification status",
  cellphoneStatus: "Cellphone verification status",
  landlineStatus: "Landline verification status",
  emailStatus: "Email verification status",
  KYCPermission: "KYC permission",
  WAPPStatus: "WhatsApp verification Status",
  autoWithdraw: "Automatic withdraw",
  googleAuthStatus: "2FA status",
  banStatus: "Ban status",
  depositPermission: "Deposit permission",
  withdrawPermission: "Withdraw permission",
  tradePermission: "Trade permission",
  VerificationPhoto: "Verification photo",
  addInfo: "Add Information",
  upload: "Upload",
  changeHistory: "Changes history",
  assetsChart: "Assets chart",
  exportPositionsHistory: "Export History of positions",
  accept: "Accept",
  cancel: "Cancel",
  reject: "Reject",
  confirmChanges: "Confirm Changes",
  cancelChanges: "Cancel Changes",
  KYCPhoto: "KYC photo",
  fullname: "Fullname",
  gender: "Gender",
  shabandari: "Narek Shabandari",
  nationalId: "National ID",
  ban: "Ban",
  verification: "Verification",
  verified: "Verified",
  uuid: "UUID",
  noResultFound: "No Result Found!",
  weCantFindAnyResultsMatchingYourSearch:
    "We cannot find any results matching your search.",
  tryAgain: "Try Again",
  moreReasons:
    "If there is another reason, please write reasons in separate lines like example.",
  rejectDocumentMessage1:
    "The resolution of the image is low and the documents are not readable",
  rejectDocumentMessage2:
    "The image does not match the authentication guidelines",
  rejectDocumentMessage3: "The absence of a national card in the picture",
  rejectDocumentMessage4: "The absence of a bank card in the picture",
  rejectDocumentMessage5: "The absence of a contract in the picture",
  rejectDocumentMessage6: "The national card is not readable",
  rejectDocumentMessage7: "The contract must be handwritten",
  rejectDocumentMessage8: "The date of the contract is not up to date",
  rejectDocumentMessage9: "The image has been manipulated by software",
  rejectDocumentMessage10: "The face is not completely clear",
  rejectDocumentMessage11:
    "The name and surname on the bank card are not readable",
  rejectDocumentMessage12: "It is obligatory to observe the Islamic hijab",
  rejectDocumentMessage13: "It's a mirror image",
  rejectDocumentMessage14: "The type of document sent is not accepted",
  rejectDocumentMessage15: "The text of the contract is wrong",
  rejectDocumentMessage16:
    "National ID code of birth certificate is not readable",
  rejectDocumentMessage17: "National ID code of passport is not readable",
  rejectDocumentMessage18: "Passport has expired and is not valid",
  rejectDocumentMessage19: "The bank card number is not shown in full ",
  rejectDocumentMessage20: "The contract has crossing",
  rejectDocumentMessage21:
    "The contract does not have a signature, date and name",
  rejectDocumentExample:
    "- Example of rejection reason (dash, space for rejection reason) If the above reasons are enough, tick each one and leave this part blank",
  changeStatus: "Change status",
  changeName: "Change name",
  dailyIRTWithdrawLimitChange: "Change daily IRT withdraw limit",
  dailyCryptoWithdrawLimitChange: "Change daily crypto withdraw limit",
  monthlyWithdrawLimitChange: "Change monthly withdraw limit",
  changeBirthday: "Change birthday",
  male: "Male",
  female: "Female",
  date: "Date",
  supportMember: "Support Member",
  user: "User",
  subject: "Subject",
  description: "Description",
  total: "Total",
  somethingWentWrong: "Something went wrong.",
  trades: "Trades",
  openOrders: "Open Positions",
  ordersHistory: "Positions History",
  dropYourFilesHere: "Drop your files here",
  successfullyUploaded: "Successfully uploaded.",
  fileIsSuccessfullyAdded: "File is successfully added.",
  fileLoading: "File loading ...",
  errorWhileUploading: "Error while uploading",
  invalidFileFormat: "Invalid file format",
  emailVerification: "Email Verification",
  depositAddress: "Deposit Address",
  memo: "Memo",
  all: "All",
  isBanned: "Banned",
  notBanned: "Not Banned",
  isVerified: "Verified",
  notVerified: "Not Verified",
  IRT: "IRT",
  crypto: "Crypto",
  dateTime: "Time",
  gateway: "Gateway",
  amount: "Amount",
  bank: "Bank",
  card: "Card number",
  moreInfo: "More",
  network: "Network",
  status: "Status",
  coin: "Coin",
  no: "No",
  yes: "Yes",
  toItemOf: "{{start}} to {{end}} items of {{total}}",
  notSet: "Not set",
  cancelled: "Cancelled",
  internal: "Internal",
  hash: "Hash",
  changeKYCStatus: "Change KYC verification status",
  changeLandlineStatus: "Change landline verification status",
  changeEmailStatus: "Change email verification status",
  changeKYCPermission: "Change KYC permission",
  changeAutoWithdrawStatus: "Change auto withdraw status",
  changeBanStatus: "Change ban status",
  changeDepositPermission: "Change deposit permission",
  changeWithdrawPermission: "Change withdraw permission",
  changeTradePermission: "Change trade permission",
  anErrorOccurred: "An Error Occurred!",
  depositInfo: "Deposit Information",
  back: "Back",
  checkDocument: "Please check user's photo",
  currentName: "Current Name",
  currentBirthday: "Current Birthday",
  currentIRTWithdrawLimit: "Current Daily IRT Withdraw Limit",
  currentCryptoWithdrawLimit: "Current Daily Crypto Withdraw Limit",
  currentMonthlyWithdrawLimit: "Current Monthly Withdraw Limit",
  currentKYCPermission: "Current KYC Permission",
  currentAutomaticWithdrawPermission: "Current Automatic Withdraw Permission",
  currentDepositPermission: "Current Deposit Permission",
  currentWithdrawPermission: "Current Withdraw Permission",
  currentTradePermission: "Current Trade Permission",
  currentLandlineStatus: "Current Landline Status",
  currentKYCStatus: "Current KYC Status",
  currentBanStatus: "Current Ban Status",
  dateTimeRequest: "Request Time",
  dateTimeExecute: "Execute Time",
  exportData: "Export Data",
  withdrawInfo: "Withdraw Information",
  withdrawAddress: "Withdraw Address",
  iban: "Iban",
  processing: "Processing",
  processingIRT: "Waiting for bank response",
  new: "New",
  pendingWithdraw: "Pending",
  originAddress: "Origin",
  destinationAddress: "Destination",
  details: "Details",
  orderID: "Order ID",
  deposit_uuid: "Deposit ID",
  user_uuid: "User ID",
  confirmations: "Confirmations",
  withdraw_uuid: "Withdraw ID",
  fee: "Fee",
  isAuto: "Automatic",
  send_amount: "Send Amount",
  will_execute_after_time: "Postponement",
  problematic: "Problematic",
  serverMismatch: "server Mismatch",
  tableMismatch: "table Mismatch",
  serverUnreachable: "server Unreachable",
  searchDeposits: "Search Deposits",
  searchWithdraws: "Search Withdraws",
  ibans: "Ibans",
  cards: "Cards",
  getDetails: "Get Details",
  approve: "Approve",
  notApprove: "Reject",
  actions: "Actions",
  check: "Check",
  checkAgainPlease: "Please recheck the information",
  checkPlease: "Please check the information",
  excelFileIsSuccessfullyCreated: "Excel file is successfully created.",
  darkMode: "Dark Mode",
  match: "match",
  noMatch: "No match",
  notChecked: "Not Checked",
  advancedSearch: "Advanced Search",
  cardCount: "Card Count",
  ibanCount: "Iban Count",
  referral: "referral Code",
  accountLevel: "Level",
  changeGenderStatus: "Change Gender",
  currentGender: "Current Gender",
  executeNow: "Execute Now",
  invalidUploadTypeMsg: "File type is not valid",
  invalidUploadSizeMsg: "Image size should be smaller than {{size}}.",
  auto: "Auto",
  manual: "Manual",
  normal: "Normal",
  economy: "Economy",
  KYC: "KYC",
  KYClvl1: "KYC level one",
  KYClvl1Time: "KYC level one Date",
  KYClvl2: "KYC level two",
  KYClvl2Time: "KYC level two Date",
  noInternetConnection: "No Internet Connection",
  operators: "Operators",
  management: "Management",
  levelOne: "Level 1",
  levelTwo: "Level 2",
  levelThree: "Level 3",
  levelFour: "Level 4",
  levelFive: "Level 5",
  fromDate: "Start Date",
  toDate: "End Date",
  choose: "Choose an option",
  totalOperators: "Total Operators",
  online: "Online",
  offline: "Offline",
  balance: "Balance",
  inOrder: "In Order",
  pendingInWithdraw: "Pending Withdraw",
  addresses: "Addresses",
  show: "Show",
  update: "Update",
  networks: "Networks",
  operatorProfile: "Operator Profile",
  information: "Information",
  general: "General",
  permissions: "Permissions",
  edit: "Edit",
  searchCoin: "Search a coin",
  editBalance: "Modify balances",
  nameEn: "Name in EN",
  nameFa: "Name in FA",
  chooseCoin: "Choose a coin",
  enterAmount: "Enter amount",
  increase: "Increase",
  decrease: "Decrease",
  confirmModifyBalanceIncrease: "You are about to increase user's balance?",
  confirmModifyBalanceDecrease: "You are about to decrease user's balance?",
  rejectCardMessage1: "Card number is wrong",
  rejectCardMessage2: "Duplicate card number",
  rejectCardMessage3: "Card belongs to another person",
  rejectIbanMessage1: "Iban is wrong",
  rejectIbanMessage2: "Duplicate Iban",
  rejectIbanMessage3: "Iban belongs to another person",
  bankData: "Bank's information",
  fillField: "Please Fill the form",
  pleaseSelectCoin: "Please select a coin",
  pleaseEnterAmount: "Please enter amount",
  currentAmount: "Current amount",
  newAmount: "New amount",
  pleaseEnterName: "Please enter a name",
  pleaseEnterLastName: "Please enter a family name",
  pleaseEnterGender: "Please choose a gender",
  pleaseEnterBirthday: "Please enter birthday",
  pleaseEnterReason: "Please select reasons or enter them",
  pleaseChooseOption: "Please select an option",
  pleaseChooseStatus: "Please select a status",
  pleaseEnterDescription: "Please enter a description",
  pleaseEnterTx: "Please enter a Tx",
  actionsHistory: "Actions History",
  change: "Change",
  agent: "Platform",
  ip: "ip",
  location: "location",
  time: "time",
  operator_id: "username",
  operator_uuid: "operator ID",
  operator_name: "operator Name",
  viewAllServices: "View all services",
  onlineOperators: "Online Operators",
  offlineOperators: "Offline Operators",
  operatorsActionsHistory: "Operators Actions History",
  nameUser: "User's Name",
  cellphoneUser: "User's Cellphone",
  nationalIdUser: "User's National ID",
  operatorsInfo: "Operators Information",
  addOperator: "Add an operator",
  add: "Add",
  loginId: "Login ID",
  active: "Active",
  deactive: "Deactive",
  reset: "Reset",
  seen: "Seen",
  pleaseEnterCellphone: "Please enter a cellphone number",
  PleaseEnterOperatorId: "Please enter an ID",
  pleaseChoosePermission: "Please Choose permissions",
  withdrawLine: "Withdraw Line",
  successfullyCopied: "Successfully Copied",
  coinsManagement: "Coins Management",
  image: "Symbol",
  depositMin: "Minimum Deposit",
  withdrawMin: "Minimum Withdraw",
  depositFee: "Deposit Fee",
  withdrawFee: "Withdraw Fee",
  depositStatus: "Deposit Status",
  withdrawStatus: "Withdraw Status",
  icon: "Icon",
  IRTEquivalent: "IRT Equivalent",
  USDTEquivalent: "USDT Equivalent",
  totalUsersBalances: "Total Users Balances",
  download: "Download",
  DocumentPhoto: "KYC Photo",
  selectLanguage: "Select Language",
  ticketPlatform: "Ticket Platform",
  createTime: "Create Time",
  lastUpdateTime: "Last Update time",
  ticketId: "Ticket Id",
  title: "Title",
  creator: "Creator",
  assignee: "Assignee",
  searchTicket: "Search Ticket",
  open: "Open",
  reOpen: "Reopen",
  close: "Close",
  closed: "Closed",
  allTickets: "All Tickets",
  openTickets: "Open Tickets",
  send: "Send",
  createTicket: "Create Ticket",
  operator: "Operator",
  text: "Text",
  pleaseEnterTitle: "Please enter a title",
  pleaseEnterAssigneeNumber: "Please enter a phone",
  pleaseEnterText: "Please enter text",
  history: "History",
  assign: "Assign",
  currentAssignee: "Current Assignee",
  seeDetails: "View Details",
  systemHealth: "System Health",
  searchActionsHistory: "Search Actions History",
  action: "Action",
  referrals: "Referrals",
  irtDeposits: "IRT Deposits",
  cryptoDeposits: "Crypto Deposits",
  irtWithdraws: "IRT Withdraws",
  cryptoWithdraws: "Crypto Withdraws",
  tradeInfo: "Trade Information",
  bidOrderID: "Bid Order ID",
  askOrderID: "Ask Order ID",
  buyerUuid: "Buyer Uuid",
  buyerName: "Buyer Name",
  buyerEmail: "Buyer Email",
  buyerNid: "Buyer National ID",
  buyerPhone: "Buyer Phone",
  sellerUuid: "Seller Uuid",
  sellerName: "Seller Name",
  sellerEmail: "Seller Email",
  sellerNid: "Seller National ID",
  sellerPhone: "Seller Phone",
  symbol: "Symbol",
  volume: "Volume",
  price: "Price",
  pendingOrder: "Open",
  sl: "Stop Loss",
  filledp: "Filled (%)",
  filled: "Filled",
  type: "Type",
  side: "Side",
  updateTime: "Last Update Time",
  tokenSymbol: "Token Symbol",
  tokenName: "Token Name",
  fullnameEn: "Network Fullname in En",
  fullnameFa: "Network Fullname in Fa",
  isDefault: "Is Default",
  hasMemo: "Has Memo",
  memoRegex: "Memo Regex",
  addressUrl: "Address URL",
  addressRegex: "Address Regex",
  transactionUrl: "Transaction URL",
  tokenDecimal: "Token Decimal",
  hasDepositFee: "Has Deposit Fee",
  hasDepositFeePercentage: "Has Deposit Fee Percentage",
  depositFeePercentage: "Deposit Fee Percentage",
  minimumDeposit: "Min. Deposit",
  withdrawPrecision: "Withdraw Precision",
  minimumWithdraw: "Min. Withdraw",
  contractAddress: "Contract Address",
  contractAddressUrl: "Contract Address URL",
  confirmationCount: "Confirmation Count",
  pleaseEnterVIPLevel: "Please enter a level",
  currentLevel: "Current level",
  currentFamilyName: "Current Family Name",
  uploadedDocuments: "Uploaded documents",
  uploadTime: "Upload time",
  documentDetails: "Document details",
  firstName: "Name",
  lastName: "Family name",
  telegramId: "Telegram ID",
  telegramChatId: "Telegram chat ID",
  changeVipLevel: "Change VIP Level",
  more: "More",
  myTickets: "My Tickets",
  newTickets: "New Tickets",
  customerName: "Customer name",
  hideFromUser: "Hide from user",
  selectAll: "Select all",
  userCellphone: "User cellphone",
  taggedDepositPermission: "Tagged deposit permission",
  taggedDepositPermissionText:
    "You are activating users tagged deposit permission, do you confirm?",
  confirm: "Confirm",
  inputDescription: "Input description",
  level0: "Level 0",
  level1: "Level 1",
  level2: "Level 2",
  level3: "Level 3",
  clickToPreview: "Click on image to preview",
  pleaseEnterNid: "Please enter national ID",
  notice: "Notice",
  refNumber: "Referral number",
  bypass_irip: "Abroad access",
  bypass_iripChange: "Change access from abroad",
  currentBypass_iripPermission: "Current bypass permission",
  withdrawDelay: "Withdraw delay",
  currentWithdrawDelay: "Current Withdraw delay",
  captchaResult: "Result",
  pleaseChooseOperator: "Please choose an operator",
  irt: "IRT",
  changes: "Changes",
  tickets: "Tickets",
  zibal: "Zibal",
  pay: "Pay",
  vandar: "Vandar",
  jibit: "Jibit",
  sync: "Sync",
  userChanges: "User actions",
  system: "System",
  years: "years",
  months: "months",
  days: "days",
  age: "Age",
  searchUsers: "Search users",
  fileUpdate: "{{number}} ready to be uploaded",
  pleaseEnterMessage: "Please enter a message",
  fileTypeError: "File type not supported",
  fileSizeError: "File size is larger than {{maxFileSize}}MB",
  assetsDetails: "Asset details",
  sell: "Sell",
  buy: "Buy",
  market: "Market",
  limit: "Limit",
  tp: "Take profit",
  sendSMS: "Send SMS",
  smsText: "SMS content",
  pleaseUseEnglishNumbers: "Please use English numbers",
  buyerFee: "Buyer fee",
  sellerFee: "Seller fee",
  usersActions: "Users actions",
  lastUserChange: "Last change",
  action2: "Action",
  lastLoginDate: "Last login",
  tfa: "2fa",
  tfaTurnOffText:
    "You are deactivating users two factor authentication, do you confirm?",
  OperatorCreator: "Creator Operator",
  coldWalletBalance: "Cold Wallet Balances",
  wait72Hour: "wait 72h",
  payroll: "Payroll",
  spendTime: "Spend time",
  spend: "Spend",
  topup: "Top up",
  excel: "Excel",
  pleaseSelectTimeRange: "Please select a time range",
  delete: "Delete",
  deletePayrollWarnText: "Your are about to delete payroll, do you confirm?",
  rial: "Rial",
  reasons: "Reasons",
} as const;

export default en;
