import { route, string } from "react-router-typesafe-routes/dom";

export const ROUTES = {
  LOGIN: route("login"),

  DASHBOARD: route("dashboard"),
  USERS: route("users"),
  USER_PROFILE: route("user_profile", {
    searchParams: {
      userUuid: string(),
    },
  }),
  KYC: route("kyc"),
  DEPOSITS: route("deposits"),
  WITHDRAWS: route("withdraws"),
  BANK_ACCOUNTS: route("bank_accounts"),
  MANAGEMENT: route("management"),
  COIN_DATA_MANAGEMENT: route("coins_management"),
  OPERATORS_MANAGEMENT: route("operators_management"),
  TOTAL_BALANCES: route("total_balances"),
  USER_ACTIONS: route("user_actions"),
  COLD_BALANCES: route("cold_balances"),
  PAYROLL: route("payroll"),

  TICKET: route("ticket"),
  TICKET_DETAIL: route("ticket_detail", {
    searchParams: {
      ticketId: string(),
    },
  }),

  OPERATOR_PROFILE: route("operator_profile"),
  SERVICE_HEALTH: route("service_health"),
};

// const ROUTES = {
//     USER: route(
//         // This is a normal path pattern, but without leading or trailing slashes.
//         // By default, path params are inferred from the pattern.
//         "user/:id",
//         {
//             // We can override some or all path params. Here, we override 'id'.
//             // We specify that an error will be thrown in case of an absent/invalid param.
//             // For demonstration purposes only, normally you shouldn't throw.
//             params: { id: number().defined() },
//             // These are search params.
//             // We specify a default value to use in case of an absent/invalid param.
//             searchParams: { infoVisible: boolean().default(false) },
//             // These are state fields, which are similar to search params.
//             // By default, 'undefined' is returned in case of an absent/invalid param.
//             state: { fromUserList: boolean() },
//             // These are allowed hash values.
//             // We could also use hashValues() to indicate that any hash is allowed.
//             hash: hashValues("info", "comments"),
//         },
//         // This is a child route, which inherits all parent params.
//         // Note how it has to start with an uppercase letter.
//         // As a reminder, its path params are inferred from the pattern.
//         { DETAILS: route("details/:lang?") }
//     ),
// };
